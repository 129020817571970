<template>
  <router-view/>
</template>

<style lang="scss">
@import "assets/fonts/stylesheet.css";
@import "assets/scss/variables.scss";

body {
  margin: 0;
  min-height: 100vh;
  color: $white;
  font-family: $main-font-family;
}

* {
  box-sizing: border-box;
}

h2 {
  font-size: 36px;
  font-size: 1.9vw;
  line-height: 2.2vw;
  margin-bottom: 10px;

  @media (max-width: $mobile) {
    font-size: 5.8vw;
    line-height: 6.6vw;
  }
}

h4 {
  font-size: 22px;
  font-size: 1.16vw;
  line-height: 1.2vw;
  font-weight: normal;
  margin: 0;

  @media (max-width: $mobile) {
    font-size: 3.86vw;
    line-height: 4.2vw;
  }
}

.text--center {
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ecb760;
}

::-webkit-scrollbar-thumb {
  background: #d49c41;
}

::-webkit-scrollbar-thumb:hover {
  background: #ca9239;
}

@media (min-width: $mobile) {
  .mobile--only {
    display: none;
  }
}

@media (max-width: $mobile) {
  .desktop--only {
    display: none;
  }
}
</style>