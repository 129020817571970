<template>
    <div class="wrapper">
        <h2>404 Not found</h2>
    </div>
</template>

<style lang="scss" scoped>
    .wrapper {
        height: 100vh;
        background-image: url('../assets/images/background.jpg');
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h2 {
        color: #69989D;
    }
</style>